import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import SaveBtn from "../../common/SaveBtn";
import RecipientInfo from "./RecipientInfo";
import RecipientTimeline from "./RecipientTimeline";
import { toast } from "react-toastify";
import FormViewer from "../../common/FormGenerator/FormViewer";
import RecipientRdv from "./RecipientRdv/RecipientRdv";
import FormRdv from "./FormRdv";
import { modalActions } from "../../../context/actions/modalActions";
import Simulator from "../Simulator/Simulator";
import FormViewerPdf from "../../common/FormGenerator/FormViewerPdf";
import roleConfig from "../../common/layout/roleConfig";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import Reorientations from "../Reorientations/Reorientations";
import CERModule from "./CERModule/CERModule";
import SynchroFTStatus from "./SynchroFTStatus";

const RecipientDetail = (props) => {
  const [state, dispatch] = useStore();
  const modal_actions = modalActions(state, dispatch);
  const recipientState = {
    email: "",
    nir: "",
    gender: "",
    firstname: "",
    lastname: "",
    birthdate: "",
    cityId: "",
    address: "",
    addressAdd: "",
    location: {
      cityId: "",
      postalCode: "",
      name: "",
      locationName: "",
    },
    phone: "",
    phone2: "",
    maritalStatus: "",
    nbChildrenOrDependant: "",
    CAFId: "",
    rights: [],
    origin: "",
    rightsAndDuties: [],
    contracts: [],
    professionalProject: "",
    driveLicenses: [],
    transports: [],
    numSubscribePE: "",
    userManager: "",
    jobSearch: "",
    jobSearchExp: "",
    job2Search: "",
    job2SearchExp: "",
  };
  const [recipient, setRecipient] = useState(recipientState);
  const [events, setEvents] = useState(false);
  const [rdsps, setRdsps] = useState(false);
  const [rdvs, setRdvs] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [allDisabled, setAllDisabled] = useState(
    state.auth?.user
      ? roleConfig[state.auth.user.role].includes("update_recipients")
      : false
  );
  const [formConfig, setFormConfig] = useState(false);
  const [isLoadingRdvs, setIsLoadingRdvs] = useState(false);
  const [isHistoricVisible, setIsHistoricVisible] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [isRdvVisible, setIsRdvVisible] = useState(false);
  const [isCERVisible, setIsCERVisible] = useState(false);
  const [isTimelineVisible, setIsTimelineVisible] = useState(true);
  const [hasRdv, setHasRdv] = useState(false);
  const [rdspId, setRdspId] = useState(false);
  const [showDl, setShowDl] = useState(false);
  const [saveInfoBtnVisible, setSaveInfoBtnVisible] = useState(false);
  const [infoUpdated, setInfoUpdated] = useState(false);
  const [mouseOverSaveBtn, setMouseOverSaveBtn] = useState(false);
  const [orgs, setOrgs] = useState([]);

  //var idToGet = props.recipientId ? props.recipientId : props.match.params.id;
  const [idToGet, setIdToGet] = useState(
    props.recipientId ? props.recipientId : props.match.params.id
  );

  useEffect(() => {
    if (idToGet) {
      getDatas();
    }
  }, [idToGet]);

  useEffect(() => {
    if (props.recipientId) {
      setIdToGet(props.recipientId);
    }
  }, [props.recipientId]);

  function getDatas() {
    dataService.get("organizations/orientation_partners", setOrgs);
    if (idToGet != "create") {
      setIsLoading(true);
      dataService.get(
        `recipients/${idToGet}`,
        (data) => {
          setRecipient({ ...data, cityId: data.location?.cityId });
        },
        setErrors,
        () => setIsLoading(false)
      );
    }

    dataService.get(`rdsp`, setFormConfig, setErrors);
    dataService.get(`recipients/${idToGet}/timeline`, setEvents, setErrors);
    dataService.get(
      `recipients/${idToGet}/rdsp`,
      (data) => setRdsps(data.reverse()),
      setErrors
    );
    dataService.get(
      `rdsp/recipients/${idToGet}/check`,
      (data) => {
        var { rdsp, rdv } = data;
        if (!rdsp) {
          toast.info("Aucun RDSP rempli par cet allocataire");
        } else {
          setRdspId(rdsp);
        }
        if (rdv) {
          setHasRdv(true);
        }
      },
      (err) =>
        toast.error(
          "Une erreur s'est produite pendant la récupération des informations"
        )
    );

    if (state.auth?.user.role != "AGENT_PARTNER") getRdvs();
  }

  function getRdvs() {
    setIsLoadingRdvs(true);
    dataService.get(`rdv/${idToGet}`, setRdvs, setErrors, () => {
      setIsLoading(false);
      setIsLoadingRdvs(false);
    });
  }

  function updateData(e) {
    setIsSaved(false);
    var { type, name, value, checked, formArray } = e.target;
    var usr = JSON.parse(JSON.stringify(recipient));
    if (type == "checkbox") {
      if (checked) {
        value = true;
      } else {
        value = false;
      }
    } else if (formArray) {
      var elem = usr[name];
      if (type == "checkBtnList") {
        if (elem.includes(value)) {
          elem = elem.filter((v) => v != value);
        } else {
          elem.push(value);
        }
      } else {
        if (formArray.target.checked) {
          elem.push(value);
        } else {
          elem = elem.filter((v) => v != value);
        }
      }
    } else {
      elem = value;
    }
    usr[name] = elem;
    setInfoUpdated(true);
    setRecipient(usr);
  }

  function resendActivationMail() {
    setIsLoading(true);
    dataService.get(
      `recipients/${recipient.id}/renew-activation`,
      (data) => {
        toast.success(
          `L'email d'activation a bien été renvoyé à ${recipient.email}`
        );
      },
      (err) => {
        toast.error(
          "Une erreur s'est produite pendant l'envoi de l'email d'activation"
        );
        setErrors(err);
      },
      () => setIsLoading(false)
    );
  }

  function save() {
    setIsSaving(true);
    setIsSaved(false);
    setErrors({});
    const recipientDatas = {
      ...recipient,
      rights: recipient.rights.filter((r) => r.type),
      rightsAndDuties: recipient.rightsAndDuties.filter((r) => r.beginAt),
      contracts: recipient.contracts.filter((c) => c.type),
    };
    if (idToGet == "create") {
      //create
      dataService.post(
        `recipients`,
        recipientDatas,
        (data) => {
          setRecipient({ ...data, cityId: data.location?.cityId });
          setIsSaving(false);
          setIsSaved(true);
          toast.success("Création de l'allocataire réussie");
          window.location.href = "/recipients/" + data.id;
        },
        setErrors,
        () => {
          setIsSaving(false);
          setInfoUpdated(false);
        }
      );
    } else {
      //update
      dataService.patch(
        `recipients/${props.match.params.id}`,
        recipientDatas,
        (data) => {
          setRecipient({ ...data, cityId: data.location?.cityId });
          setIsSaving(false);
          setIsSaved(true);
          toast.success("Modification de l'allocataire réussie");
        },
        setErrors,
        () => {
          setIsSaving(false);
          setInfoUpdated(false);
        }
      );
    }
  }

  function unlockAccount() {
    dataService.get(`recipients/${idToGet}/authorization/reset`, (data) =>
      toast.success("Compte débloqué avec succès.", (err) => {
        toast.error(
          "Une erreur est survenue lors du déblocage de ce compte allocataire."
        );
        setErrors(err);
      })
    );
  }

  function addRdsp(rdspDatas = false) {
    modal_actions.updateModal({
      isOpen: true,
      content: (
        <Simulator
          recipient={recipient}
          rdsp={rdspDatas}
          successCallback={getDatas}
        />
      ),
    });
  }

  function openReorientations() {
    modal_actions.updateModal({
      isOpen: true,
      content: <Reorientations userRecipientId={idToGet} isModal={true} />,
    });
  }

  return state.constants.items.length == 0 || isLoading ? (
    <Loader />
  ) : (
    <Page
      bgTransparent
      noMargin={props.recipientId}
      container={"container-fluid " + (props.recipientId ? "" : "px-5")}
      title={
        props.recipientId ? (
          false
        ) : (
          <div>
            {tools.getFullname(recipient)}
            {recipient.archivedAt && (
              <small className="badge badge-dark ml-2">
                <i className="fa fa-archive mr-1" />
                Archivage le{" "}
                {DateTime.fromISO(recipient.archivedAt).toFormat("dd/MM/yyyy")}
              </small>
            )}
          </div>
        )
      }
      errors={errors}
      back={props.recipientId ? false : "/recipients"}
    >
      <div className="row">
        {recipient && recipient.lastOrientation && (
          <div className="col-12 mb-2">
            <div className="card rounded">
              <div className="card-body py-2 ">
                <div className="d-flex flex-wrap justify-content-between align-items-center w-100">
                  <div
                    className="py-2 d-flex align-items-center justify-content-start flex-wrap"
                    style={{ gap: 10 }}
                  >
                    <span
                      className="badge bg-body border"
                      style={{ width: "fit-content" }}
                    >
                      Orientation
                    </span>
                    {
                      <strong>
                        {tools.findIn(
                          orgs,
                          "slugName",
                          recipient.lastOrientation.orientation
                        ).name || "N.D"}
                      </strong>
                    }
                    <div className="text-muted" style={{ fontSize: 12 }}>
                      {DateTime.fromISO(
                        recipient.lastOrientation.createdAt
                      ).toFormat("dd/MM/yyyy")}
                    </div>
                  </div>
                  <div
                    className="py-2 d-flex align-items-center justify-content-start flex-wrap"
                    style={{ gap: 10 }}
                  >
                    {recipient.lastOrientation?.createdAt &&
                      DateTime.fromISO(recipient.lastOrientation.createdAt) >
                        DateTime.fromISO("2025-01-01T00:00:00.000Z") && (
                        <SynchroFTStatus
                          sentToFTAt={recipient.lastOrientation.sentToFTAt}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* INFORMATIONS */}

        <div className={"col-12"} style={{ animationDelay: "100ms" }}>
          {!props.recipientId && recipient.spouse && (
            <a
              className="btn btn-sm btn-link float-right mb-2 border-0"
              href={"/recipients/" + recipient.spouse}
              target="_BLANK"
            >
              Voir le/la conjoint(e)
            </a>
          )}
          <button
            className="d-flex flex-row flex-wrap align-items-center justify-content-between btn w-100  bg-primary text-white py-3 animated fadeInUp faster mb-1"
            onClick={() => setIsInfoVisible(!isInfoVisible)}
            style={{ animationDelay: "100ms" }}
          >
            <span className="font-weight-bold">Informations</span>
            <span>
              <i className="fa fa-eye mr-2" />
              Afficher/Masquer
            </span>
          </button>
          {/* {allDisabled && (
            <button
              className="btn btn-default btn-sm mb-2"
              onClick={() => setAllDisabled(false)}
            >
              <i className=" fa fa-edit mr-2" />
              Modifier les informations de l'allocataire
            </button>
          )} */}
          <div className="px-4" style={{ overflow: "hidden" }}>
            {isInfoVisible && (
              <>
                <RecipientInfo
                  recipient={recipient}
                  allDisabled={allDisabled}
                  genders={state.constants.items.GENDERS}
                  maritalStatuses={state.constants.items.MARITAL_STATUS}
                  errors={errors}
                  updateData={updateData}
                  resendActivationMail={resendActivationMail}
                  unlockAccount={unlockAccount}
                  rdsps={rdsps}
                  refresh={getDatas}
                />

                {!props.recipientId && (
                  <SaveBtn
                    margin="mx-auto my-3 py-3 align-items-center justify-content-center"
                    className={infoUpdated ? "animated pulse infinite" : ""}
                    text="Enregistrer les informations"
                    save={save}
                    isSaving={isSaving}
                    isSaved={isSaved}
                  />
                )}

                {!props.recipientId && (
                  <div
                    onMouseEnter={() => setMouseOverSaveBtn(true)}
                    onMouseLeave={() => setMouseOverSaveBtn(false)}
                  >
                    <SaveBtn
                      margin="mx-auto my-3 py-3 align-items-center justify-content-center position-fixed animated"
                      className={`${
                        infoUpdated
                          ? `${
                              mouseOverSaveBtn ? "pulse" : "jello"
                            } faster infinite`
                          : "bounceInRight"
                      } 
                      
                      `}
                      style={{ bottom: 0, right: 0, zIndex: "9999" }}
                      text={
                        <>
                          {mouseOverSaveBtn && (
                            <span className="mr-2 animated fadeInRight faster">
                              Enregistrer
                            </span>
                          )}{" "}
                          <i className="fa fa-save px-2" />
                        </>
                      }
                      save={save}
                      isSaving={isSaving}
                      isSaved={isSaved}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {/* CONTRAT CER */}
        {recipient.id && (
          <div className={"col-12"} style={{ animationDelay: "100ms" }}>
            <button
              className="d-flex flex-row flex-wrap align-items-center justify-content-between btn w-100  bg-primary text-white py-3 animated fadeInUp faster mb-1"
              onClick={() => setIsCERVisible(!isCERVisible)}
              style={{ animationDelay: "100ms" }}
              data-priv="crud_cer"
            >
              <span className="font-weight-bold">
                Contrat CER{" "}
                {recipient.lastOrientation?.orientation != "CAF" && (
                  <span className="badge badge-light ml-2">
                    <i className="fa fa-exclamation-triangle mr-1" />
                    allocataire non CAF
                  </span>
                )}
              </span>
              <span>
                <i className="fa fa-eye mr-2" />
                Afficher/Masquer
              </span>
            </button>

            {isCERVisible && (
              <div className="px-4" style={{ overflow: "hidden" }}>
                <CERModule recipient={recipient} />
              </div>
            )}
          </div>
        )}

        {/* RDSP */}
        <div className={"col-12"} style={{ animationDelay: "0ms" }}>
          {rdsps && formConfig && (
            <>
              <button
                className="d-flex flex-row flex-wrap align-items-center justify-content-between btn w-100    bg-primary text-white py-3 animated fadeInUp faster mb-1"
                data-priv="view_rdsp"
                onClick={() => setIsHistoricVisible(!isHistoricVisible)}
                style={{ animationDelay: "0ms" }}
              >
                <span className="font-weight-bold">Historique des RDSP</span>
                <span>
                  <i className="fa fa-eye mr-2" />
                  Afficher/Masquer
                </span>
              </button>
              <div className="px-4 " style={{ maxWidth: 800 }}>
                {isHistoricVisible && (
                  <>
                    <div id="accordion" className="mb-3">
                      {rdsps.map((r, rk) => (
                        <div key={"rdspcard" + rk} className="mb-2 ">
                          <div className=" p-0" id={"header-rdsp" + rk}>
                            <button
                              className="btn btn-default border bg-white w-100 d-flex justify-content-between font-weight-bold align-items-center"
                              data-toggle="collapse"
                              data-target={"#rdsp" + rk}
                              aria-expanded="true"
                              aria-controls={"rdsp" + rk}
                            >
                              <span>
                                {"RDSP du " +
                                  DateTime.fromISO(r.createdAt).toFormat(
                                    "dd/MM/yyyy"
                                  )}
                              </span>
                              <div className="font-weight-normal">
                                {r.version === 2 && (
                                  <SynchroFTStatus sentToFTAt={r.sentToFTAt} />
                                )}
                                <i className="fa fa-ellipsis-v ml-2" />
                              </div>
                            </button>
                          </div>
                          <div
                            id={"rdsp" + rk}
                            className="collapse"
                            aria-labelledby={"header-rdsp" + rk}
                            data-parent="#accordion"
                          >
                            <div className="card-body border-0">
                              <div className="d-flex align-items-center justify-content-between">
                                {r.version === 2 && (
                                  <SynchroFTStatus sentToFTAt={r.sentToFTAt} />
                                )}
                                {/* <button
                                  data-priv="modify_rdsp"
                                  className="btn btn-default mr-1"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Attention, seuls les agents habilités à orienter ou à accompagner le bénéficiaire du RSA sont autorisés à modifier le RDSP."
                                      )
                                    ) {
                                      addRdsp(r);
                                    }
                                  }}
                                >
                                  <i className="fa fa-edit mr-2" />
                                  Modifier ce RDSP
                                </button> */}

                                <button
                                  className="btn btn-default"
                                  onClick={() => setShowDl(r.id)}
                                >
                                  Télécharger en PDF
                                </button>
                              </div>
                              {showDl == r.id && (
                                <FormViewerPdf
                                  form={r.config}
                                  answers={r.rdsp}
                                  recipient={recipient}
                                />
                              )}
                              <FormViewer form={r.config} answers={r.rdsp} />
                            </div>
                          </div>
                        </div>
                      ))}
                      {rdsps.length == 0 && (
                        <div className=" mt-2" data-priv="create_rdsp">
                          <span style={{ fontSize: 12 }}>
                            Si l'allocataire ne possède encore aucun RDSP, vous
                            pouvez en créer un manuellement
                          </span>
                          <button
                            className="btn btn-primary mt-2 d-center"
                            onClick={() => addRdsp(false)}
                          >
                            <i className="fa fa-plus mr-2" />
                            Ajouter un nouveau RDSP
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>

        {/* RDV */}
        <div className={"col-12"} style={{ animationDelay: "200ms" }}>
          <button
            className="d-flex flex-row flex-wrap align-items-center justify-content-between btn w-100 bg-primary text-white py-3 animated fadeInUp faster mb-1 "
            onClick={() => setIsRdvVisible(!isRdvVisible)}
            style={{ animationDelay: "200ms" }}
            data-priv="view_recipient_appointments"
          >
            <span className="font-weight-bold">Historique des rendez-vous</span>
            <span>
              <i className="fa fa-eye mr-2" />
              Afficher/Masquer
            </span>
          </button>
          {isRdvVisible && isLoadingRdvs ? (
            <Loader />
          ) : (
            <div className="px-4  ">
              {isRdvVisible && !hasRdv && rdspId && (
                <div className="my-3">
                  <p className="alert alert-danger mb-2">
                    Aucun rendez-vous positionné pour le moment, vous pouvez en
                    créer un à l'aide du formulaire ci-dessous
                  </p>
                  <FormRdv rdspId={rdspId} userData={{ id: idToGet }} />
                </div>
              )}

              {isRdvVisible && (
                <>
                  {rdvs ? (
                    <RecipientRdv
                      rdvs={rdvs}
                      errors={errors}
                      setErrors={setErrors}
                      recipientId={idToGet}
                      getRdvs={getDatas}
                      isLoading={isLoadingRdvs}
                      rdspId={rdspId}
                      recipient={recipient}
                    />
                  ) : (
                    <Loader />
                  )}
                </>
              )}
            </div>
          )}
        </div>

        {/* TIMELINE */}
        <div className={"col-12"} style={{ animationDelay: "300ms" }}>
          {events && (
            <>
              <button
                className="d-flex flex-row flex-wrap align-items-center justify-content-between btn w-100   bg-primary text-white py-3  animated fadeInUp faster mb-1"
                onClick={() => setIsTimelineVisible(!isTimelineVisible)}
                style={{ animationDelay: "300ms" }}
              >
                <span className="font-weight-bold">Parcours</span>
                <span>
                  <i className="fa fa-eye mr-2" />
                  Afficher/Masquer
                </span>
              </button>
              <div className="">
                {isTimelineVisible && (
                  <>
                    <div className="d-flex justify-content-end w-100">
                      <button
                        className="btn btn-dark"
                        onClick={openReorientations}
                      >
                        <i className="fa fa-map-signs mr-2" />
                        Ouvrir l'historique des orientations
                      </button>
                    </div>
                    <RecipientTimeline events={events} />
                  </>
                )}
              </div>{" "}
            </>
          )}
        </div>
      </div>
    </Page>
  );
};

export default RecipientDetail;
