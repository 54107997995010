import { atom, useAtom } from "jotai";
import dataService from "../../../helpers/dataService";
import useOrganizations from "../../Hooks/useOrganizations/useOrganizations";
import { toast } from "react-toastify";

export const recipientStockAtom = atom([]);
export const isLoadingRecipientStockAtom = atom(false);
export const recipientStockFiltersAtom = atom({
  filters: {
    terms: "",
    noEmail: false,
    orientation: "",
    isEpInProgress: false,
    isReorientationInProgress: false,
  },
  page: "1",
  nbItemsByPage: 25,
});

export const nbResultsAtom = atom(0);
export const currentRecipientStockAtom = atom(null);
export const nbPagesAtom = atom(0);
export const isLoadingCurrentRecipientStockAtom = atom(false);
export const isSavingCurrentRecipientStockAtom = atom(false);

const useRecipientStock = () => {
  const [recipientStock, setRecipientStock] = useAtom(recipientStockAtom);
  const [isLoadingRecipientStock, setIsLoadingRecipientStock] = useAtom(
    isLoadingRecipientStockAtom
  );

  const [recipientStockFilters, setRecipientStockFilters] = useAtom(
    recipientStockFiltersAtom
  );
  const [nbResults, setNbResults] = useAtom(nbResultsAtom);
  const [currentRecipientStock, setCurrentRecipientStock] = useAtom(
    currentRecipientStockAtom
  );
  const [isLoadingCurrentRecipientStock, setIsLoadingCurrentRecipientStock] =
    useAtom(isLoadingCurrentRecipientStockAtom);
  const [isSavingCurrentRecipientStock, setIsSavingCurrentRecipientStock] =
    useAtom(isSavingCurrentRecipientStockAtom);
  const [nbPages, setNbPages] = useAtom(nbPagesAtom);

  const onSearchRecipientStock = (overrideFilter = null) => {
    setIsLoadingRecipientStock(true);
    const appliedFilters = overrideFilter || recipientStockFilters;
    let isEpInProgressValue = undefined;
    if (appliedFilters.filters.isEpInProgress) {
      isEpInProgressValue = true;
    }
    let isReorientationInProgressValue = undefined;
    if (appliedFilters.filters.isReorientationInProgress) {
      isReorientationInProgressValue = true;
    }
    dataService.post(
      `recipient-stocks/search`,
      {
        ...appliedFilters,
        filters: {
          ...appliedFilters.filters,
          orientation: appliedFilters.filters.orientation || undefined,
          isEpInProgress: isEpInProgressValue,
          isReorientationInProgress: isReorientationInProgressValue,
        },
      },
      (data) => {
        setRecipientStock(data.data.map((d) => ({ ...d, id: d.id || d._id })));
        setNbResults(data.count);
        setNbPages(
          Math.ceil(parseInt(data.count) / appliedFilters.nbItemsByPage)
        );
      },
      (err) => {},
      () => setIsLoadingRecipientStock(false)
    );
  };

  const onGetRecipientStockById = (id) => {
    setIsLoadingCurrentRecipientStock(true);
    dataService.get(
      `recipient-stocks/${id}`,
      setCurrentRecipientStock,
      (err) => {},
      setIsLoadingCurrentRecipientStock(false)
    );
  };

  const onFiltersChange = (e) => {
    const { name, value, type, checked } = e.target;
    var realValue = value;
    if (type == "checkbox") {
      realValue = checked ? true : false;
    }
    setRecipientStockFilters({
      ...recipientStockFilters,
      filters: {
        ...recipientStockFilters.filters,
        [name]: realValue,
      },
    });
  };

  const changePage = (page) => {
    const newFilters = {
      ...recipientStockFilters,
      page,
    };
    setRecipientStockFilters(newFilters);
    onSearchRecipientStock(newFilters);
  };
  const changeNbItemsByPage = (nbItemsByPage) => {
    const newFilters = {
      ...recipientStockFilters,
      nbItemsByPage,
    };
    setRecipientStockFilters(newFilters);
    onSearchRecipientStock(newFilters);
  };

  const updateRecipientEmail = (id, email) => {
    setIsSavingCurrentRecipientStock(true);
    dataService.patch(
      `recipient-stocks/${id}`,
      { email },
      (data) => {
        setCurrentRecipientStock((r) => ({ ...r, email }));
        toast.success(
          "L'email de l'allocataire du stock a bien été mis à jour"
        );
      },
      (error) => {},
      () => setIsSavingCurrentRecipientStock(false)
    );
  };

  return {
    recipientStock,
    isLoadingRecipientStock,
    recipientStockFilters,
    nbResults,
    nbPages,
    isLoadingCurrentRecipientStock,
    isSavingCurrentRecipientStock,
    currentRecipientStock,
    onSearchRecipientStock,
    onFiltersChange,
    onGetRecipientStockById,
    changePage,
    changeNbItemsByPage,
    updateRecipientEmail,
  };
};

export default useRecipientStock;
