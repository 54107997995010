import React from "react";
import { modalActions } from "../../../context/actions/modalActions";
import useStore from "../../../context/useStore";
import tools from "../../../helpers/tools";
import useSectors from "../../Hooks/useSectors/useSectors";
import AddRecipientToCrossInterview from "../RecipientDetail/AddRecipientToCrossInterview";
import AddRecipientToEp from "../RecipientDetail/AddRecipientToEp";
import useRecipientStock from "../RecipientStockList/useRecipientStock";
import { toast } from "react-toastify";

const RecipientStockActions = () => {
  const { currentRecipientStock, onGetRecipientStockById } =
    useRecipientStock();
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);
  const { sectors } = useSectors();

  const addRecipientToCrossInterview = () => {
    if (!currentRecipientStock.email) {
      toast.error(
        "Veuillez renseigner l'email de l'allocataire avant de demander une réorientation."
      );
      return;
    }
    mActions.updateModal({
      isOpen: true,
      content: (
        <AddRecipientToCrossInterview
          recipient={currentRecipientStock}
          onSaved={() => onGetRecipientStockById(currentRecipientStock.id)}
          authorizedTargetOrganizationsSlugNames={["CD", "PE"]}
        />
      ),
    });
  };

  const addRecipientToNextEp = () => {
    mActions.updateModal({
      isOpen: true,
      content: (
        <AddRecipientToEp
          recipient={currentRecipientStock}
          refresh={() => onGetRecipientStockById(currentRecipientStock.id)}
        />
      ),
    });
  };

  const canSeeActionButtons = () => {
    return (
      !currentRecipientStock.currentEP &&
      !currentRecipientStock.currentOrientation
    );
  };

  return (
    <div className="row mb-3">
      <div className="col-12 text-right">
        {currentRecipientStock.currentOrientation && (
          <div className="badge badge-primary mr-1">
            Réorientation demandée depuis le{" "}
            {tools.formatDate(
              currentRecipientStock.currentOrientation.createdAt
            )}
          </div>
        )}
        {currentRecipientStock.currentEP && (
          <div className="badge badge-info">
            Inscrit à l'EP du{" "}
            {tools.formatDate(currentRecipientStock.currentEP?.EP?.beginAt)}{" "}
            secteur{" "}
            {
              tools.findIn(
                sectors,
                "id",
                currentRecipientStock.currentEP.EP.sector
              ).name
            }
          </div>
        )}
        {canSeeActionButtons() && (
          <button
            className="btn btn-primary mr-2"
            onClick={addRecipientToCrossInterview}
          >
            Demander une réorientation
          </button>
        )}
        {canSeeActionButtons() && (
          <button className="btn btn-primary" onClick={addRecipientToNextEp}>
            Inscrire à la prochaine EP
          </button>
        )}

        {!canSeeActionButtons() && (
          <p className="text-danger">
            Inscription en EP et réorientation non disponibles car une de ces
            procédures est déjà en cours.
          </p>
        )}
      </div>
    </div>
  );
};

export default RecipientStockActions;
